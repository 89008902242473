
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lato&family=Libre+Baskerville&family=Merriweather:wght@700&family=Work+Sans&display=swap");


.patient-answer-box{
  width: 100%;
  max-width: 100%;
  outline: none;
  border: none;
}

/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 32px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.animated {
  overflow: hidden;
  height: 0px; /* Set the desired expanded height */
  transition: height 0.3s ease;
}

.animated.expanded {
  height: 35px; /* Set the desired expanded height */
}



.Blink {
  margin-left: 10px;
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}
